<template>
    <section style="background: #FFFFFF;">
        <!--组织展示-->
        <el-col :span="3">
             <div class="orgbtndivtree">
                <el-divider>部门</el-divider>
                <el-cascader :props="props" v-model="cascadervalue" ref="department" 
                clearable size="mini" :show-all-levels="false" 
                 popper-class="depc" :options="deptOptions"
                @change="cascaderchange" filterable></el-cascader>
            </div>
            <div class="orgtree">
             <!--中间列表-->
                <el-table
                    :data="departusers"
                    highlight-current-row
                    :row-style="{height:'40px'}"
                    :cell-style="{padding:'0px'}"
                    empty-text="请选择部门"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    @current-change="selectDepartUserRow"
                    >
                    <el-table-column type="index" width="70px"></el-table-column>
                    <el-table-column label="姓名" prop="name" align="left"></el-table-column>
                </el-table>
             <!-- 分页器 -->
            <el-pagination v-if="Pagination" align='center' @current-change="handleCurrentChangeDepart" :current-page="DepartcurrentPage" :page-size="DepartpageSize" layout="prev, pager, next" :page-count="Departtotal" :total="Departtotaldata">
            </el-pagination>
         </div>
        </el-col>
        <!--工作数据展示-->
        <el-col :span="21">
            <div>
                <el-form :inline="true"
                        style="width: 100%;overflow-x: auto;white-space: nowrap;margin-left: 10px;margin-top: 10px;padding-bottom:0px;"
                        size="mini">
                        <el-form-item label="选择月份筛选">
                            <el-date-picker v-model="lookdate"
                                            type="month"
                                            @change="handleDateChange"
                                            :clearable="false"
                                            placeholder="选择查看月份"></el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="handleExport" >导出</el-button>
                        </el-form-item>
                </el-form>
                <el-divider>集团各部门绩效评估报表</el-divider>
            </div>
            <div v-show="isdepartment">
                <el-table
                    id="mytable"
                    :data="users" 
                    row-key = "Idaaaaaaaaaaa"
                    lazy
                    :indent="30"
                    :show-summary="true"
                    :summary-method="getSummaries"
                    :span-method="objectSpanMethod"
                    :load = "load"
                    :tree-props = "{children: 'children',hasChildren: 'IsHasChildren'}"
                    :fit="true"
                    :row-style="{height:'40px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    style="width: 100%;height:100%;">
                        <el-table-column type = "index"></el-table-column>
                        <el-table-column label="工作项目" prop="WorkItem" min-width="170px" show-overflow-tooltip></el-table-column>
                        <!-- <el-table-column label="工作描述" prop="WorkDescription" min-width="170px" show-overflow-tooltip ></el-table-column> -->
                        <el-table-column label="责任人" prop="PersonOfDuty" min-width="70px"></el-table-column>
                        <el-table-column label="权重" prop="Rate" min-width="50px" align="center" >
                            <template slot-scope = "scope">
                                <div>
                                    <font color=black>{{scope.row.Rate}}</font>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="评估月份" prop="Month" min-width="100px">
                            <template slot-scope = "scope">
                                <div v-if=" scope.row.Month==null ">
                                    <font color=red>暂未评分</font>
                                </div>
                                <div v-else>
                                    <font color=black>{{scope.row.Month}}</font>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="评估得分" prop="Score" min-width="100px" >
                            <template slot-scope = "scope">
                                <div v-if=" scope.row.Month==null && scope.row.Score==0 ">
                                    <font color=black></font>
                                </div>
                                <div v-else>
                                    <font :color="scope.row.Score<scope.row.Rate?'red':'black'">{{scope.row.Score}}分</font>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="评价" prop="Description" min-width="170px" ></el-table-column>
                        <el-table-column label="操作" min-width="100px">
                            <template slot-scope="scope">
                                <el-tooltip  placement="left" effect="light" content="点击查看年度任务详情及考核方式">
                                    <el-button type="text" size="medium" @click="checkInfo(scope.row)">查看任务</el-button>
                                </el-tooltip>
                                <el-button v-show="currentUserName=='刘东'" type="text" size="medium" @click="delReview(scope.row)">删除评分</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    
                    
            </div>
        </el-col>


        <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" headerText="查看年度计划任务" type="yearplan" ></taskinfo>
    </section>
</template>
<script>
import util from '../../../util/date';
import FileSaver from "file-saver";
import XLSX from "xlsx";
import taskinfo from "../../components/taskinfo"
import {DeleteTaskPingfen,QueryPeoplePingfenReport,GetChildrenList,QueryPageTodayOperatorProcessByUsercode,QueryPageTodayOperatorTasksByUsercode,QueryTasksById,QueryTaskProgressByTaskId,QueryChildTasksByParentId,GetUserTopDepartmentList,GetDepartmentPostUserList} from "../../api/oa"

export default {
     components: { taskinfo },
    data(){
        return{
            currentUserName:'',
            lookdate:new Date(),
            Pagination: true,
            currentPage: 1, // 当前页码
            total: null, // 总条数
            totaldata:null,
            processtotal:null,
            processtotaldata:null,
            processlist:[],
            pageSize: 20000, // 每页的数据条数
            page: 1,
            DepartcurrentPage: 1, // 当前页码
            Departtotal: null, // 总条数
            Departtotaldata:null,
            DepartpageSize:  1000, // 每页的数据条数
            Departpage: 1,
            departmentsearchdata: [],
            TypeIdModules: [],   //部门类型集合
            users: [],
            departusers: [],
            departmentList: [],
            DptProps: {
                label: 'Name',
                isLeaf: 'Leaf'
            },
            currentRow: null,
            DepartUserRow: null,
            workItem: null,
            taskClassify: 0,
            taskStatus: 0,
            progressValue: null,
            deptinput: null,
            madedate: null,
            cascadervalue: null,
            ClassifyModules:[
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "日常工作"
                },
                {
                    value: 2,
                    label: "跨部门协调"
                },
                {
                    value: 3,
                    label: "例会项目"
                },
                {
                    value: 4,
                    label: "年度计划"
                },
                {
                    value: 5,
                    label: "专项会议"
                },
            ],
            StatusModules: [
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "进行中"
                },
                {
                    value: 2,
                    label: "已完成"
                },
            ],
            deptOptions:[],
            checkVisible: false,
            checkForm: {            //查看表单初始化
                Id: null,
                WorkItem: null,
                WorkDescription: null,
                PlanComplateTime: null,
                Remark: null,
                IsNeedApproval: null
            },
            showAssign: false,
            createPerson: false,                    //创建人是否显示
            showyearplan: false,                    //年度计划查看界面显示控制
            showorganize: false,                     //跨部门协同查看界面显示控制
            showregularproject: false,               //例会来源查看界面控制
            showprofessionmeetong: false,            //专项会议查看界面控制
            CommonTaskClassifyText: null,
            pValue1: null,   //进度条数值
            istaskpro: false,
            tasks: [],
            options: [],
            checkdepartid: null,
            departuserid: null,
            departmentName:'',
            props: {
                checkStrictly: true,
                value:'id',
                label:'label'
            },
            props2: {
                // checkStrictly: true,
                lazy: false,
                lazyLoad (node, resolve) {
                    const { level,value } = node;
                    if(node.level == 0){
                        var user = JSON.parse(window.localStorage.user);
                        var userid="b57e4ab5-7df4-482b-a8f3-a36f012b963f";
                        GetUserTopDepartmentList({userId: userid}).then(res => {
                            const nodes = res.data.data.map(x=>({
                                value: x.deptId,
                                label: x.deptName,
                                leaf: !x.hasNode
                            }))
                            resolve(nodes)                         
                        });
                    }else{

                        // GetChildrenList({deptId: node.value}).then(res => {
                        //     const nodes = res.data.data.dept.map(x=>({
                        //         value: x.id,
                        //         label: x.label,
                        //         leaf: !x.hasChildren
                        //     }))
                        //     resolve(nodes)
                        // })



                        GetChildrenList({deptId: node.value}).then(res => {
                            // console.log(res);
                            var mydata = res.data.data;
                            if(mydata.dept[0].children!=null)
                            {
                                const nodes = res.data.data.dept[0].children.map(x=>({
                                    value: x.id,
                                    label: x.label,
                                    leaf: !x.hasChildren
                                }))
                                // console.log(nodes);
                                resolve(nodes)   
                            }else{
                                resolve([]);
                            }
                        })
                    }
                    
                }
            },
            YearPlanTaskList: [],
            YearPlanTask: null,
            YearPlanTaskId: null,
            isdepartment: false,
            departid: [],
            peoplecode:[],
            key: 0,
            userid: '',
            isuser: false,
            spanArr:[],
            pos:0,
            spanArr2:[],
            pos2:0,
            spanArr3:[],
            pos3:0
        }
    },
    methods:{
        checkclose () {
            this.checkVisible = false;
            //this.getYearPlans();
        },
        handleExport()
        {
            this.exportExcel();
        },
        //定义导出Excel表格事件
        exportExcel() {
            /* 从表生成工作簿对象 */
            var wb = XLSX.utils.table_to_book(document.querySelector("#mytable"));
            /* 获取二进制字符串作为输出 */
            var wbout = XLSX.write(wb, {
                bookType: "xlsx",
                bookSST: true,
                type: "array"
            });
            var filename=this.departmentName;
            try {
                FileSaver.saveAs(
                //Blob 对象表示一个不可变、原始数据的类文件对象。
                //Blob 表示的不一定是JavaScript原生格式的数据。
                //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                new Blob([wbout], { type: "application/octet-stream" }),
                //设置导出文件名称
                filename+"绩效评估报表.xlsx"
                );
            } catch (e) {
                if (typeof console !== "undefined") console.log(e, wbout);
            }
            return wbout;
        },
        merageInit() {
            this.spanArr = [];
            this.pos = 0;
            this.spanArr2 = [];
            this.pos2 = 0;
            this.spanArr3 = [];
            this.pos3 = 0;
        },
         getSpanArr(data) {　
            //console.log(data) 
            this.merageInit();
            for (var i = 0; i < data.length; i++) {
                if (i === 0) {
                    this.spanArr.push(1);
                    this.pos = 0
                    this.spanArr2.push(1);
                    this.pos2 = 0;
                } else {
                    // 判断当前元素与上一个元素是否相同
                    if (data[i].WorkItem === data[i - 1].WorkItem) {
                        this.spanArr[this.pos] += 1;
                        this.spanArr.push(0);
                    } else {
                        this.spanArr.push(1);
                        this.pos = i;
                    }
                    if (data[i].WorkDescription === data[i - 1].WorkDescription && data[i].WorkItem === data[i - 1].WorkItem) {
                        this.spanArr2[this.pos2] += 1;
                        this.spanArr2.push(0);
                    } else {
                        this.spanArr2.push(1);
                        this.pos2 = i;
                    }
                    if (data[i].PersonOfDuty === data[i - 1].PersonOfDuty && data[i].WorkDescription === data[i - 1].WorkDescription && data[i].WorkItem === data[i - 1].WorkItem) {
                        this.spanArr3[this.pos3] += 1;
                        this.spanArr3.push(0);
                    } else {
                        this.spanArr3.push(1);
                        this.pos3 = i;
                    }
                }
                //console.log(this.spanArr)
            }
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }else if(index==5)
                {
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                        }, 0);
                        var a = sums[5]
                        sums[index] += '分';
                        var b = sums[3];
                        // console.log(a)
                        // console.log(b)
                        sums[index] += ',完成率'+(a/b*100).toFixed(1)+'%';
                    } else {
                        sums[index] = 'N/A';
                    }
                }
                else if(index==3)
                {
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                        }, 0);
                        sums[index] += '';
                    } else {
                        sums[index] = 'N/A';
                    }
                }
            });

            return sums;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        //   if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        //   if (columnIndex === 1 || columnIndex === 2 || columnIndex === 3) {
        //   if (columnIndex === 1 ) {
        //     const _row = this.spanArr[rowIndex];
        //     const _col = _row > 0 ? 1 : 0;
        //     //console.log(`rowspan:${_row} colspan:${_col}`)
        //     return { // [0,0] 表示这一行不显示， [2,1]表示行的合并数
        //           rowspan: _row,
        //           colspan: _col
        //     }
        //   }
        //    else if (columnIndex === 2) {
        //     // 第二列的合并方法
        //     const row2 = this.spanArr2[rowIndex];
        //     const col2 = row2 > 0 ? 1 : 0; // 如果被合并了row = 0; 则他这个列需要取消
        //     return {
        //         rowspan: row2,
        //         colspan: col2,
        //     };
        //   }
        //   else if (columnIndex === 3) {
        //     // 第三列的合并方法
        //     const row3 = this.spanArr3[rowIndex];
        //     const col3 = row3 > 0 ? 1 : 0; // 如果被合并了row = 0; 则他这个列需要取消
        //     return {
        //         rowspan: row3,
        //         colspan: col3,
        //     };
        //   }
        },
        getLookDate:function()
        {
            return this.lookdate;
        },
        handleDateChange()
        {
            // console.log(this.lookdate)
            if(this.checkdepartid!=null)
            {
                // this.getDepartUsers(this.checkdepartid);
                this.LoadReportData();
            }
        },
        call(arr)
        {
            let para = {
                userCode: arr[0],
                date:this.lookdate,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            QueryPageTodayOperatorTasksByUsercode(para).then((res) => {
                //console.log(res)
                 this.total = res.data.response.pageCount;
                 this.totaldata = res.data.response.dataCount;
                 this.users = res.data.response.data;
            });
            var username=arr[1];
            let para2 = {
                userCode: username,
                date:this.lookdate,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            //console.log(para2)
            QueryPageTodayOperatorProcessByUsercode(para2).then((res) => {
                //console.log(res)
                 this.processtotal = res.data.response.pageCount;
                 this.processtotaldata = res.data.response.dataCount;
                 this.processlist = res.data.response.data;
            });
        },
        delReview(row)
        {
            let self=this;
            //return;
            this.$confirm("确定要删除"+row.PersonOfDuty+"的"+row.Month+"月份评分吗?(删除后无法恢复)", "提示", {
                //type: 'warning'
            })
            .then(() => {
                
                var id=row.Id;
                let para = {id: id};
                DeleteTaskPingfen(para).then((res) => {
                    //   this.tasks = res.data.response;
                    var data =res.data.response;
                    if(data)
                    {
                        this.$message({
                            message: '删除成功',
                            type: 'success',
                            duration: 5000
                        });
                        self.LoadReportData();
                    }else{
                        this.$message({
                            message: '删除失败',
                            type: 'warning',
                            duration: 7000
                        });
                    }
                });
            })
            .catch(() => {});
        },
       //双击查看详细信息
        checkInfo(row){
        //   console.log(row)
          this.checkForm = row;
          this.checkVisible = true;
          return;
        if(row.TaskClassify ==1){  
            // this.CommonTaskClassifyText = row.CommonTaskClassifyText;
            this.CommonTaskClassifyText = "日常工作";
                this.showorganize = false;
                this.showyearplan = false;
                this.showregularproject = false;
                this.showprofessionmeetong = false;
                if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                }else{
                   this.showAssign = false; 
                }
                this.createPerson = true;
        }else{
                if(row.TaskClassify ==2)
                {
                    this.CommonTaskClassifyText = "跨部门协调";
                    this.showorganize = true;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }
                else if(row.TaskClassify ==3)
                {
                    this.CommonTaskClassifyText =  "例会项目";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = true;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = true;
                }else if(row.TaskClassify ==4)
                {
                    this.CommonTaskClassifyText =  "年度计划";
                    this.showorganize = false;
                    this.showyearplan = true;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }else if(row.TaskClassify ==5){
                    this.CommonTaskClassifyText = "专项会议";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = true;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = true;
                }
                else {
                    this.CommonTaskClassifyText =  "其他";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }
            }
            QueryTasksById({Id: row.TaskId,taskClassify: row.TaskClassify}).then((res) => {
                this.checkForm = res.data.response;
                this.checkForm.Files=[];
                if(res.data.response.FileName!=null)
                {
                    var arr=res.data.response.FileName.split(",");
                    var arr2=res.data.response.FileAddress.split(",");
                    var files=[];
                    for(var a=0;a<arr.length;a++)
                    {
                        var f={};
                        f.FileName=arr[a];
                        f.FileAddress=arr2[a];
                        files.push(f);
                    }
                    this.checkForm.Files=files;
                }
            })
          this.pValue1 = row.TaskProgressValue;
        //   this.checkForm = Object.assign({},row);
          let para = {taskId: row.TaskId};
          QueryTaskProgressByTaskId(para).then((res) => {
            //   this.tasks = res.data.response;
              var data =res.data.response;
              let formatData=new Array();
              for(var a=0;a<data.length;a++)
              {
                    var element = data[a];
                    element.Files=[];
                    if(element.FileName!=null)
                    {
                        var arr=element.FileName.split(",");
                        var arr2=element.FileAddress.split(",");
                        var files=[];
                        for(var d=0;d<arr.length;d++)
                        {
                            var f={};
                            f.FileName=arr[d];
                            f.FileAddress=arr2[d];
                            files.push(f);
                        }
                        element.Files=files;
                    }
                    formatData.push(element);
              }
              this.tasks = formatData;
          });
        },
        selectDepartUserRow(val){
            if(val==null){
                return;
            }
            this.DepartUserRow = val;
            this.departuserid = val.id;
            this.peoplecode=[];
            this.peoplecode.push(val.id);
            var month=this.lookdate.getFullYear()+'-'+Number(this.lookdate.getMonth()+1);
            QueryPeoplePingfenReport({peoples:this.peoplecode,month:month}).then(res => {
                var response = res.data.response.data;
                this.users=response;
                this.getSpanArr(this.users);
            });

        },
        YearPlanchange(val){
            this.YearPlanTask = val.split('|')[2];
            this.YearPlanTaskId = val.split('|')[1];
        },
        load(tree,treeNode,resolve){
            let para = {
                taskId: tree.Id,
            };
            QueryChildTasksByParentId(para).then((res) => {
                resolve(res.data.response)
            });
        },
        formatPlanComplateTime: function (row,colume) {
            return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime),'yyyy-MM-dd');
        },
        formatCreatedOn: function (row,colume) {
                return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn),'yyyy-MM-dd hh:mm');
            },
        formatCommonTaskClassifyText: function(row,colume){
            if(row.TaskClassify ==1){  
                // return row.CommonTaskClassifyText;
                return "日常工作";
            }else{
                if(row.TaskClassify ==2)
                {
                    return "跨部门协调";
                }
                else if(row.TaskClassify ==3)
                {
                    return "例会项目";
                }else if(row.TaskClassify ==4)
                {
                    return "年度计划";
                }else if(row.TaskClassify ==5){
                    return "专项会议";
                }
                else {
                    return "其他";
                }
            }
        },
        formatSource: function (row,column) {
                return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
            },
        formatCreateTime: function(row,column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
            },
        formatTipCreateTime: function (row, column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime),'yyyy-MM-dd hh:mm');
            },
        formatTipEndTime: function (row, column) {
                return (!row.ENDTIME || row.ENDTIME == '') ? '' : util.formatDate.format(new Date(row.ENDTIME),'yyyy-MM-dd hh:mm');
            },
        formatEndTime: function (row, column) {
                return (!row.ENDTIME || row.ENDTIME == '') ? '' : util.formatDate.dateformat(new Date(row.ENDTIME));
            },
        //翻页处理
        handleCurrentChange(val) {
           this.page = val;
           this.getManagerChecks();
           this.page = 1;
      },
      getManagerChecks(){
           //时间转化
             let begintime = "";  //开始时间
             let endtime = "";    //结束时间
            if(this.madedate != null){
                switch (this.madedate.length) {
                    case 1:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    break;
                    case 2:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    endtime += util.formatDate.format(new Date(this.madedate[1]), 'yyyy-MM-dd');
                    break;
                    default:
                    break;
                }    
            }
            if(this.departuserid == null){
                 this.$message({
                    message: '请先选择想要查看任务的部门人员',
                    type: 'warning',
                    duration: 7000
                });
                return;
            }
            let para = {
                userCode: this.departuserid,
                workItem: this.workItem,
                taskClassify: this.taskClassify,
                taskStatus: this.taskStatus,
                begin: begintime,
                end: endtime,
                yearplanId: this.YearPlanTaskId,
                progressValue: this.progressValue,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            QueryPageTodayOperatorTasksByUsercode(para).then((res) => {
                 this.total = res.data.response.pageCount;
                 this.totaldata = res.data.response.dataCount;
                 this.users = res.data.response.data;
            });
      },
        cascaderchange(node){
            // console.log(node)
            if(node.length>0){
                var deptValue = node[node.length-1];
                this.checkdepartid = deptValue;
                this.isdepartment = true;
                this.getDepartUsers(deptValue);
            }
        },
        cascaderchange2(node){
            // 目的是选择之后将下拉界面收起
            this.$refs.department.toggleDropDownVisible();
            this.Pagination = false;
            let pathvalue = this.$refs.department.getCheckedNodes()[0];
            this.departmentName=pathvalue.label;
            this.checkdepartid = pathvalue.value;
            this.getDepartUsers(pathvalue.value);
            this.isdepartment = true;
            this.isuser = false;
            // this.key += 1;
            this.$refs.department.dropDownVisible = false;
        },
        handleCurrentChangeDepart(val){
           if(this.checkdepartid==null)
           {
               return;
           }
           this.Departpage = val;
           this.getDepartUsers(this.checkdepartid);
           this.Departpage = 1;
        //    this.key += 1;
        },
        LoadReportData()
        {
            //console.log(this.departusers);
            if(this.DepartUserRow==null){
                this.peoplecode=[];
                this.departusers.forEach(item => {
                    this.peoplecode.push(item.id);
                });
            }
            this.users=[]
            //console.log(this.peoplecode)
            var month=this.lookdate.getFullYear()+'-'+Number(this.lookdate.getMonth()+1);
            QueryPeoplePingfenReport({peoples:this.peoplecode,month:month}).then(res => {
                var response = res.data.response.data;
                this.users=response;
                this.getSpanArr(this.users);
            });
        },
        //获取部门人员
        getDepartUsers(node){
            let para = {
                deptId: node,
                isAllNextDept:false,
                pageNum: this.Departpage,
                pageSize: this.DepartpageSize,
                includeChildren:true,
                onlyPrincipal:true
            }
            GetDepartmentPostUserList(para).then(res => {
                var data=res.data.data.list;
                let newdata=this.unique(data);
                this.departusers = newdata;
                this.Pagination = true;
                this.departid = newdata;
                // this.departid = res.data.response.data;
                this.key += 1;
                this.Departtotal = res.data.data.pages;
                this.Departtotaldata = res.data.data.total;
                this.peoplecode=[];
                // setTimeout(() => {
                //     this.LoadReportData();
                // }, 200);
            });

        },
        unique(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
        },
        loadDeptOptions(){
            GetChildrenList().then((res) => {
                // console.log(res);
                if(res.data.code==200){
                    var dept = res.data.data.dept;
                    this.deptOptions = dept;
                }
            });
        }

    },
    mounted(){
        var user = JSON.parse(window.localStorage.user);
        //console.log(user)
        this.currentUserName=user.name;
        //console.log(this.currentUserName)
        this.loadDeptOptions();
    }
}
</script>
<style scoped>
.orgtree {
  /* overflow-y: auto;
  overflow-x: scroll; */
  height: calc(100vh - 280px);
  width:200px;
  border: 0px solid blue;
  padding-top: 35px;
}
.orgbtndivtree {
  padding-left: 20px;
  padding-right: 20px;
  
}
/deep/.el-table__body tr.current-row>td {
  background: #FABE64 !important;
}
/deep/input:disabled,/deep/textarea:disabled{
    opacity: 1;
    -webkit-text-fill-color: rgb(12, 12, 12);
}
.managercheckdiv {
 height: 600px;
}
#managercheckcard{
    height: 600px;
}
.tasksdiv {
    max-height: 400px;
    overflow: auto;
}
/deep/.el-table .warning-row {
    background: #F0F8FF;
}
/deep/.el-button--goon {
  color: #FFF;
  background-color: #20B2AA;
  border-color: #20B2AA;
}
/deep/.el-button--goon:hover {
  background: #48D1CC;
  border-color: #48D1CC;
  color: #fff;
}
/deep/.el-button--goon:focus {
  background: #20B2AA;
  border-color: #20B2AA;
  color: #fff;
}
</style>